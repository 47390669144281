:root {
  --scale: 0.88235;
  --space: 5px;
  --back: #022449;
  --menu-color: #022449;
  --border-color: #022449;
  --burger-hover: #55bdca;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  visibility: hidden;
  transition: visibility 0.2s, height 0.2s;
  overflow: hidden;
  background: black;
  z-index: 1;
}

.burger-shower {
  position: absolute;
  left: 80px;
  /* top: 30px; */
}

.burger-shower:hover {
  cursor: pointer;
}

#main-menu {
  display: block;
  height: 100px;
  width: 100px;
  background: var(--back);
  margin: 0px;
  z-index: 9;
}

#main-menu ul {
  max-width: 800px;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 9;
}

#main-menu li {
  list-style-type: none;
  font-size: 2rem;
}

.menu-label2 {
  color: var(--text-color);
  font-family: var(--font);
  font-size: 24px;
  text-align: left;
  padding-left: 12px;
  background-color: transparent;
  border: none;
}

.menu-label2:hover {
  text-decoration: underline;
  cursor: pointer;
}

#hamburger-input {
  display: none;
}

.line {
  position: relative;
  width: min(37px, 4vw);
  height: min(0.4vw, 6px);
  border-radius: 6px;
  margin-bottom: min(0.75vw, 8px);
  margin-top: min(0.75vw, 8px);
  background-color: var(--line-color);
}

.line-container {
  position: relative;
  width: min(37px, 4vw);
  height: fit-content;
  background-color: transparent;
  vertical-align: center;
  /* top: 0px; */
  margin-left: 20px;
  z-index: 2;

  --line-color: var(--text-color);
  /* border: 1px solid black; */
}

.line-container:hover {
  --line-color: var(--burger-hover);
  cursor: pointer;
}

#hamburger-menu {
  /* touch-action: none; */
  position: relative;
  /* top: 18px; */
  /* left: 20px; */
  width: min(50px, 5vw);
  /* height: 40px; */
  border: none;
  padding: 0px;
  margin: 0px;
  font-family: var(--font);
  z-index: 9;
}

#hamburger-menu #sidebar-menu {
  visibility: hidden;
  position: fixed;
  top: 0px;
  left: -330px;
  width: 300px;
  height: 100%;
  background-color: var(--menu-color);
  transition: 0.3s;
  padding: 0px 10px;
  box-sizing: border-box;
  z-index: 9;
}

#hamburger-menu h3 {
  color: var(--text-color);
  font-size: 2.5rem;
}

#hamburger-menu ul {
  padding-left: 0px;
}

#hamburger-menu li {
  list-style-type: none;
  line-height: 3rem;
}

#hamburger-menu a {
  color: var(--text-color);
  font-size: 1.3rem;
  text-decoration: none;
}

#hamburger-menu a:hover {
  text-decoration: underline;
}

#hamburger-input:checked + #hamburger-menu #sidebar-menu {
  left: 0px;
  visibility: visible;
  box-shadow: 0px 0px 10px 3px var(--border-color);
}

#hamburger-input:checked ~ .overlay {
  visibility: visible;
  opacity: 0.4;
}

@media screen and (max-width: 750px) {
  #main-menu {
    display: none;
  }

  #hamburger-menu {
    display: inline;
  }
}

.xout {
  position: absolute;
  top: 20px;
  left: calc(100% - 50px);
}
.line2 {
  width: 30px;
  height: 5px;
  border-radius: 6px;
  margin-top: 7px;
  background-color: var(--text-color);
  transform: rotate(45deg);
}
.line3 {
  width: 30px;
  height: 5px;
  border-radius: 6px;
  margin-top: 7px;
  background-color: var(--text-color);
  transform: rotate(-45deg) translateY(-9px) translateX(8px);
}

.xout:hover {
  cursor: pointer;
}
.xout:hover div[class^="line2"] {
  background-color: var(--burger-hover);
}
.xout:hover div[class^="line3"] {
  background-color: var(--burger-hover);
}

.input-container {
  display: grid;
  grid-template-columns: 1fr;
}
.menu-line {
  position: relative;
  width: 95%;
  height: 3px;
  border-radius: 1.5px;
  top: -15px;
  left: 2.5%;
  background-color: var(--text-color);
}

@media (max-width: 500px) {
  #hamburger-menu #sidebar-menu {
    visibility: hidden;
    position: fixed;
    top: 0px;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--menu-color);
    transition: 0.3s;
    padding: 0px 10px;
    box-sizing: border-box;
    z-index: 9;
  }
}
