.test {
  background-color: black;
}
#pendulum {
  position: absolute;
  top: 100px;
  left: 100px;
}
#buttons {
  position: absolute;
  width: 450px;
  background-color: white;
  border-top: 1px solid black;
  top: 550px;
  left: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
#variable {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  width: 60px;
}

.pause-button {
  position: absolute;
  top: 100px;
  left: 560px;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: rgb(255, 186, 186);
}
.pause-button:hover {
  background-color: #e3a9a9;
}
#instructions {
  position: absolute;
  top: 90px;
  left: 140px;
  font-weight: bold;
  font-size: 16px;
  background-color: white;
}
